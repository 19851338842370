/**
* Template Name: NiceAdmin
* Updated: Nov 17 2023 with Bootstrap v5.3.2
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
(function() {
    "use strict";

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
        return [...document.querySelectorAll(el)]
        } else {
        return document.querySelector(el)
        }
    }

    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    document.addEventListener("click", function(e){

        // Sidebar toggle
        if(e.target.closest(".toggle-sidebar-btn")) {
            select('body').classList.toggle('toggle-sidebar')
        } else if(!e.target.closest(".sidebar")) {
            select('body').classList.remove('toggle-sidebar')
        } else if(e.target.closest("a") && !e.target.closest("a").hasAttribute('data-bs-toggle')) {
            setTimeout(() => {
                select('body').classList.remove('toggle-sidebar')
            }, 100)
        }
    });

    const headerScrolled = () => {
        let selectHeader = select('#header')
        if (selectHeader) {
            if (window.scrollY > 100) {
            selectHeader.classList.add('header-scrolled')
            } else {
            selectHeader.classList.remove('header-scrolled')
            }
        }
    }
    window.addEventListener('load', headerScrolled)
    onscroll(document, headerScrolled)

    const toggleBacktotop = () => {
        let backtotop = select('.back-to-top')
        if (backtotop) {
            if (window.scrollY > 100) {
            backtotop.classList.add('active')
            } else {
            backtotop.classList.remove('active')
            }
        }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
})();
